export const GET_ORGANIZATION_REQUEST = "GET_ORGANIZATION_REQUEST";
export const GET_ORGANIZATION_SUCCESS = "GET_ORGANIZATION_SUCCESS";
export const GET_ORGANIZATION_ERROR = "GET_ORGANIZATION_ERROR";

export const GET_SINGLE_ORGANIZATION_REQUEST =
  "GET_SINGLE_ORGANIZATION_REQUEST";
export const GET_SINGLE_ORGANIZATION_SUCCESS =
  "GET_SINGLE_ORGANIZATION_SUCCESS";
export const GET_SINGLE_ORGANIZATION_ERROR = "GET_SINGLE_ORGANIZATION_ERROR";

export const ADD_ORGANIZATION_REQUEST = "ADD_ORGANIZATION_REQUEST";
export const ADD_ORGANIZATION_SUCCESS = "ADD_ORGANIZATION_SUCCESS";
export const ADD_ORGANIZATION_ERROR = "ADD_ORGANIZATION_ERROR";

export const EDIT_ORGANIZATION_REQUEST = "EDIT_ORGANIZATION_REQUEST";
export const EDIT_ORGANIZATION_SUCCESS = "EDIT_ORGANIZATION_SUCCESS";
export const EDIT_ORGANIZATION_ERROR = "EDIT_ORGANIZATION_ERROR";

export const DELETE_ORGANIZATION_REQUEST = "DELETE_ORGANIZATION_REQUEST";
export const DELETE_ORGANIZATION_SUCCESS = "DELETE_ORGANIZATION_SUCCESS";
export const DELETE_ORGANIZATION_ERROR = "DELETE_ORGANIZATION_ERROR";

export const DELETE_BULK_ORGANIZATION_REQUEST =
  "DELETE_BULK_ORGANIZATION_REQUEST";
export const DELETE_BULK_ORGANIZATION_SUCCESS =
  "DELETE_BULK_ORGANIZATION_SUCCESS";
export const DELETE_BULK_ORGANIZATION_ERROR = "DELETE_BULK_ORGANIZATION_ERROR";

export const GET_DEPARTMENT_REQUEST = "GET_DEPARTMENT_REQUEST";
export const GET_DEPARTMENT_SUCCESS = "GET_DEPARTMENT_SUCCESS";
export const GET_DEPARTMENT_ERROR = "GET_DEPARTMENT_ERROR";

export const ADD_DEPARTMENT_REQUEST = "ADD_DEPARTMENT_REQUEST";
export const ADD_DEPARTMENT_SUCCESS = "ADD_DEPARTMENT_SUCCESS";
export const ADD_DEPARTMENT_ERROR = "ADD_DEPARTMENT_ERROR";

export const EDIT_DEPARTMENT_REQUEST = "EDIT_DEPARTMENT_REQUEST";
export const EDIT_DEPARTMENT_SUCCESS = "EDIT_DEPARTMENT_SUCCESS";
export const EDIT_DEPARTMENT_ERROR = "EDIT_DEPARTMENT_ERROR";

export const DELETE_DEPARTMENT_REQUEST = "DELETE_DEPARTMENT_REQUEST";
export const DELETE_DEPARTMENT_SUCCESS = "DELETE_DEPARTMENT_SUCCESS";
export const DELETE_DEPARTMENT_ERROR = "DELETE_DEPARTMENT_ERROR";

export const DELETE_BULK_DEPARTMENT_REQUEST = "DELETE_BULK_DEPARTMENT_REQUEST";
export const DELETE_BULK_DEPARTMENT_SUCCESS = "DELETE_BULK_DEPARTMENT_SUCCESS";
export const DELETE_BULK_DEPARTMENT_ERROR = "DELETE_BULK_DEPARTMENT_ERROR";

export const GET_DIRECTORATE_REQUEST = "GET_DIRECTORATE_REQUEST";
export const GET_DIRECTORATE_SUCCESS = "GET_DIRECTORATE_SUCCESS";
export const GET_DIRECTORATE_ERROR = "GET_DIRECTORATE_ERROR";

export const ADD_DIRECTORATE_REQUEST = "ADD_DIRECTORATE_REQUEST";
export const ADD_DIRECTORATE_SUCCESS = "ADD_DIRECTORATE_SUCCESS";
export const ADD_DIRECTORATE_ERROR = "ADD_DIRECTORATE_ERROR";

export const EDIT_DIRECTORATE_REQUEST = "EDIT_DIRECTORATE_REQUEST";
export const EDIT_DIRECTORATE_SUCCESS = "EDIT_DIRECTORATE_SUCCESS";
export const EDIT_DIRECTORATE_ERROR = "EDIT_DIRECTORATE_ERROR";

export const DELETE_DIRECTORATE_REQUEST = "DELETE_DIRECTORATE_REQUEST";
export const DELETE_DIRECTORATE_SUCCESS = "DELETE_DIRECTORATE_SUCCESS";
export const DELETE_DIRECTORATE_ERROR = "DELETE_DIRECTORATE_ERROR";

export const DELETE_BULK_DIRECTORATE_REQUEST =
  "DELETE_BULK_DIRECTORATE_REQUEST";
export const DELETE_BULK_DIRECTORATE_SUCCESS =
  "DELETE_BULK_DIRECTORATE_SUCCESS";
export const DELETE_BULK_DIRECTORATE_ERROR = "DELETE_BULK_DIRECTORATE_ERROR";

export const CLEAR_ORGANIZATION_ACTION_STATUS =
  "CLEAR_ORGANIZATION_ACTION_STATUS";

export const GET_COST_CENTER_REQUEST = "GET_COST_CENTER_REQUEST";
export const GET_COST_CENTER_SUCCESS = "GET_COST_CENTER_SUCCESS";
export const GET_COST_CENTER_ERROR = "GET_COST_CENTER_ERROR";
