import React from "react";
import { Box, makeStyles } from "@material-ui/core";
const styles = makeStyles((theme) => ({
  loaderComp: {
    animationName: "$scaleEffect",
    animationDuration: "0.5s",
    animationIterationCount: "infinite",
  },
  "@keyframes scaleEffect": {
    "0%": {
      transform: "scale(1)",
    },
    "100%": {
      transform: "scale(1.2)",
    },
  },
}));
export const PageLoaderComp = () => {
  const classes = styles();
  return (
    <Box height="100vh" width="100vw" padding="0" margin="0" display="flex">
      <Box
        maxWidth="30%"
        maxHeight="30%"
        margin="auto"
        textAlign="center"
        classes={{ root: classes.loaderComp }}
      >
        <img src="/assets/png/rehlati-logo.png" alt="Loading logo" />
        <br />
        Loading ...
      </Box>
    </Box>
  );
};
