export const GET_USERS_REQUEST = "GET_USERS_REQUEST";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_ERROR = "GET_USERS_ERROR";

export const GET_BUS_OPERATOR_REQUEST = "GET_BUS_OPERATOR_REQUEST";
export const GET_BUS_OPERATOR_SUCCESS = "GET_BUS_OPERATOR_SUCCESS";
export const GET_BUS_OPERATOR_ERROR = "GET_BUS_OPERATOR_ERROR";

export const GET_SINGLE_BUS_OPERATOR_REQUEST =
  "GET_SINGLE_BUS_OPERATOR_REQUEST";
export const GET_SINGLE_BUS_OPERATOR_SUCCESS =
  "GET_SINGLE_BUS_OPERATOR_SUCCESS";
export const GET_SINGLE_BUS_OPERATOR_ERROR = "GET_SINGLE_BUS_OPERATOR_ERROR";

export const ADD_BUS_OPERATOR_REQUEST = "ADD_BUS_OPERATOR_REQUEST";
export const ADD_BUS_OPERATOR_SUCCESS = "ADD_BUS_OPERATOR_SUCCESS";
export const ADD_BUS_OPERATOR_ERROR = "ADD_BUS_OPERATOR_ERROR";

export const EDIT_BUS_OPERATOR_REQUEST = "EDIT_BUS_OPERATOR_REQUEST";
export const EDIT_BUS_OPERATOR_SUCCESS = "EDIT_BUS_OPERATOR_SUCCESS";
export const EDIT_BUS_OPERATOR_ERROR = "EDIT_BUS_OPERATOR_ERROR";

export const DELETE_BUS_OPERATOR_REQUEST = "DELETE_BUS_OPERATOR_REQUEST";
export const DELETE_BUS_OPERATOR_SUCCESS = "DELETE_BUS_OPERATOR_SUCCESS";
export const DELETE_BUS_OPERATOR_ERROR = "DELETE_BUS_OPERATOR_ERROR";

export const DELETE_BULK_BUS_OPERATOR_REQUEST =
  "DELETE_BULK_BUS_OPERATOR_REQUEST";
export const DELETE_BULK_BUS_OPERATOR_SUCCESS =
  "DELETE_BULK_BUS_OPERATOR_SUCCESS";
export const DELETE_BULK_BUS_OPERATOR_ERROR = "DELETE_BULK_BUS_OPERATOR_ERROR";

export const GET_CONTRACTOR_REQUEST = "GET_CONTRACTOR_REQUEST";
export const GET_CONTRACTOR_SUCCESS = "GET_CONTRACTOR_SUCCESS";
export const GET_CONTRACTOR_ERROR = "GET_CONTRACTOR_ERROR";

export const GET_SINGLE_CONTRACTOR_REQUEST = "GET_SINGLE_CONTRACTOR_REQUEST";
export const GET_SINGLE_CONTRACTOR_SUCCESS = "GET_SINGLE_CONTRACTOR_SUCCESS";
export const GET_SINGLE_CONTRACTOR_ERROR = "GET_SINGLE_CONTRACTOR_ERROR";

export const ADD_CONTRACTOR_REQUEST = "ADD_CONTRACTOR_REQUEST";
export const ADD_CONTRACTOR_SUCCESS = "ADD_CONTRACTOR_SUCCESS";
export const ADD_CONTRACTOR_ERROR = "ADD_CONTRACTOR_ERROR";

export const ADD_BULK_CONTRACTOR_REQUEST = "ADD_BULK_CONTRACTOR_REQUEST";
export const ADD_BULK_CONTRACTOR_SUCCESS = "ADD_BULK_CONTRACTOR_SUCCESS";
export const ADD_BULK_CONTRACTOR_ERROR = "ADD_BULK_CONTRACTOR_ERROR";

export const EDIT_CONTRACTOR_REQUEST = "EDIT_CONTRACTOR_REQUEST";
export const EDIT_CONTRACTOR_SUCCESS = "EDIT_CONTRACTOR_SUCCESS";
export const EDIT_CONTRACTOR_ERROR = "EDIT_CONTRACTOR_ERROR";

export const BULK_EDIT_CONTRACTOR_REQUEST = "BULK_EDIT_CONTRACTOR_REQUEST";
export const BULK_EDIT_CONTRACTOR_SUCCESS = "BULK_EDIT_CONTRACTOR_SUCCESS";
export const BULK_EDIT_CONTRACTOR_ERROR = "BULK_EDIT_CONTRACTOR_ERROR";

export const DELETE_CONTRACTOR_REQUEST = "DELETE_CONTRACTOR_REQUEST";
export const DELETE_CONTRACTOR_SUCCESS = "DELETE_CONTRACTOR_SUCCESS";
export const DELETE_CONTRACTOR_ERROR = "DELETE_CONTRACTOR_ERROR";

export const DELETE_BULK_CONTRACTOR_REQUEST = "DELETE_BULK_CONTRACTOR_REQUEST";
export const DELETE_BULK_CONTRACTOR_SUCCESS = "DELETE_BULK_CONTRACTOR_SUCCESS";
export const DELETE_BULK_CONTRACTOR_ERROR = "DELETE_BULK_CONTRACTOR_ERROR";

export const GET_DRIVER_REQUEST = "GET_DRIVER_REQUEST";
export const GET_DRIVER_SUCCESS = "GET_DRIVER_SUCCESS";
export const GET_DRIVER_ERROR = "GET_DRIVER_ERROR";

export const GET_SINGLE_DRIVER_REQUEST = "GET_SINGLE_DRIVER_REQUEST";
export const GET_SINGLE_DRIVER_SUCCESS = "GET_SINGLE_DRIVER_SUCCESS";
export const GET_SINGLE_DRIVER_ERROR = "GET_SINGLE_DRIVER_ERROR";

export const ADD_DRIVER_REQUEST = "ADD_DRIVER_REQUEST";
export const ADD_DRIVER_SUCCESS = "ADD_DRIVER_SUCCESS";
export const ADD_DRIVER_ERROR = "ADD_DRIVER_ERROR";

export const EDIT_DRIVER_REQUEST = "EDIT_DRIVER_REQUEST";
export const EDIT_DRIVER_SUCCESS = "EDIT_DRIVER_SUCCESS";
export const EDIT_DRIVER_ERROR = "EDIT_DRIVER_ERROR";

export const DELETE_DRIVER_REQUEST = "DELETE_DRIVER_REQUEST";
export const DELETE_DRIVER_SUCCESS = "DELETE_DRIVER_SUCCESS";
export const DELETE_DRIVER_ERROR = "DELETE_DRIVER_ERROR";

export const DELETE_BULK_DRIVER_REQUEST = "DELETE_BULK_DRIVER_REQUEST";
export const DELETE_BULK_DRIVER_SUCCESS = "DELETE_BULK_DRIVER_SUCCESS";
export const DELETE_BULK_DRIVER_ERROR = "DELETE_BULK_DRIVER_ERROR";

export const GET_CONTRACT_FOCAL_POINT_REQUEST =
  "GET_CONTRACT_FOCAL_POINT_REQUEST";
export const GET_CONTRACT_FOCAL_POINT_SUCCESS =
  "GET_CONTRACT_FOCAL_POINT_SUCCESS";
export const GET_CONTRACT_FOCAL_POINT_ERROR = "GET_CONTRACT_FOCAL_POINT_ERROR";

export const GET_SINGLE_CONTRACT_FOCAL_POINT_REQUEST =
  "GET_SINGLE_CONTRACT_FOCAL_POINT_REQUEST";
export const GET_SINGLE_CONTRACT_FOCAL_POINT_SUCCESS =
  "GET_SINGLE_CONTRACT_FOCAL_POINT_SUCCESS";
export const GET_SINGLE_CONTRACT_FOCAL_POINT_ERROR =
  "GET_SINGLE_CONTRACT_FOCAL_POINT_ERROR";

export const ADD_CONTRACT_FOCAL_POINT_REQUEST =
  "ADD_CONTRACT_FOCAL_POINT_REQUEST";
export const ADD_CONTRACT_FOCAL_POINT_SUCCESS =
  "ADD_CONTRACT_FOCAL_POINT_SUCCESS";
export const ADD_CONTRACT_FOCAL_POINT_ERROR = "ADD_CONTRACT_FOCAL_POINT_ERROR";

export const EDIT_CONTRACT_FOCAL_POINT_REQUEST =
  "EDIT_CONTRACT_FOCAL_POINT_REQUEST";
export const EDIT_CONTRACT_FOCAL_POINT_SUCCESS =
  "EDIT_CONTRACT_FOCAL_POINT_SUCCESS";
export const EDIT_CONTRACT_FOCAL_POINT_ERROR =
  "EDIT_CONTRACT_FOCAL_POINT_ERROR";

export const DELETE_CONTRACT_FOCAL_POINT_REQUEST =
  "DELETE_CONTRACT_FOCAL_POINT_REQUEST";
export const DELETE_CONTRACT_FOCAL_POINT_SUCCESS =
  "DELETE_CONTRACT_FOCAL_POINT_SUCCESS";
export const DELETE_CONTRACT_FOCAL_POINT_ERROR =
  "DELETE_CONTRACT_FOCAL_POINT_ERROR";

export const DELETE_BULK_CONTRACT_FOCAL_POINT_REQUEST =
  "DELETE_BULK_CONTRACT_FOCAL_POINT_REQUEST";
export const DELETE_BULK_CONTRACT_FOCAL_POINT_SUCCESS =
  "DELETE_BULK_CONTRACT_FOCAL_POINT_SUCCESS";
export const DELETE_BULK_CONTRACT_FOCAL_POINT_ERROR =
  "DELETE_BULK_CONTRACT_FOCAL_POINT_ERROR";

export const GET_FOCAL_POINT_REQUEST = "GET_FOCAL_POINT_REQUEST";
export const GET_FOCAL_POINT_SUCCESS = "GET_FOCAL_POINT_SUCCESS";
export const GET_FOCAL_POINT_ERROR = "GET_FOCAL_POINT_ERROR";

export const GET_SINGLE_FOCAL_POINT_REQUEST = "GET_SINGLE_FOCAL_POINT_REQUEST";
export const GET_SINGLE_FOCAL_POINT_SUCCESS = "GET_SINGLE_FOCAL_POINT_SUCCESS";
export const GET_SINGLE_FOCAL_POINT_ERROR = "GET_SINGLE_FOCAL_POINT_ERROR";

export const ADD_FOCAL_POINT_REQUEST = "ADD_FOCAL_POINT_REQUEST";
export const ADD_FOCAL_POINT_SUCCESS = "ADD_FOCAL_POINT_SUCCESS";
export const ADD_FOCAL_POINT_ERROR = "ADD_FOCAL_POINT_ERROR";

export const EDIT_FOCAL_POINT_REQUEST = "EDIT_FOCAL_POINT_REQUEST";
export const EDIT_FOCAL_POINT_SUCCESS = "EDIT_FOCAL_POINT_SUCCESS";
export const EDIT_FOCAL_POINT_ERROR = "EDIT_FOCAL_POINT_ERROR";

export const DELETE_FOCAL_POINT_REQUEST = "DELETE_FOCAL_POINT_REQUEST";
export const DELETE_FOCAL_POINT_SUCCESS = "DELETE_FOCAL_POINT_SUCCESS";
export const DELETE_FOCAL_POINT_ERROR = "DELETE_FOCAL_POINT_ERROR";

export const DELETE_BULK_FOCAL_POINT_REQUEST =
  "DELETE_BULK_FOCAL_POINT_REQUEST";
export const DELETE_BULK_FOCAL_POINT_SUCCESS =
  "DELETE_BULK_FOCAL_POINT_SUCCESS";
export const DELETE_BULK_FOCAL_POINT_ERROR = "DELETE_BULK_FOCAL_POINT_ERROR";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

export const GET_STAFF_REQUEST = "GET_STAFF_REQUEST";
export const GET_STAFF_SUCCESS = "GET_STAFF_SUCCESS";
export const GET_STAFF_ERROR = "GET_STAFF_ERROR";

export const GET_SINGLE_STAFF_REQUEST = "GET_SINGLE_STAFF_REQUEST";
export const GET_SINGLE_STAFF_SUCCESS = "GET_SINGLE_STAFF_SUCCESS";
export const GET_SINGLE_STAFF_ERROR = "GET_SINGLE_STAFF_ERROR";

export const ADD_STAFF_REQUEST = "ADD_STAFF_REQUEST";
export const ADD_STAFF_SUCCESS = "ADD_STAFF_SUCCESS";
export const ADD_STAFF_ERROR = "ADD_STAFF_ERROR";

export const EDIT_STAFF_REQUEST = "EDIT_STAFF_REQUEST";
export const EDIT_STAFF_SUCCESS = "EDIT_STAFF_SUCCESS";
export const EDIT_STAFF_ERROR = "EDIT_STAFF_ERROR";

export const DELETE_STAFF_REQUEST = "DELETE_STAFF_REQUEST";
export const DELETE_STAFF_SUCCESS = "DELETE_STAFF_SUCCESS";
export const DELETE_STAFF_ERROR = "DELETE_STAFF_ERROR";

export const DELETE_BULK_STAFF_REQUEST = "DELETE_BULK_STAFF_REQUEST";
export const DELETE_BULK_STAFF_SUCCESS = "DELETE_BULK_STAFF_SUCCESS";
export const DELETE_BULK_STAFF_ERROR = "DELETE_BULK_STAFF_ERROR";

export const GET_PASSENGER_REQUEST = "GET_PASSENGER_REQUEST";
export const GET_PASSENGER_SUCCESS = "GET_PASSENGER_SUCCESS";
export const GET_PASSENGER_ERROR = "GET_PASSENGER_ERROR";

export const GET_APPROVER_REQUEST = "GET_APPROVER_REQUEST";
export const GET_APPROVER_SUCCESS = "GET_APPROVER_SUCCESS";
export const GET_APPROVER_ERROR = "GET_APPROVER_ERROR";

export const GET_SINGLE_APPROVER_REQUEST = "GET_SINGLE_APPROVER_REQUEST";
export const GET_SINGLE_APPROVER_SUCCESS = "GET_SINGLE_APPROVER_SUCCESS";
export const GET_SINGLE_APPROVER_ERROR = "GET_SINGLE_APPROVER_ERROR";

export const ADD_APPROVER_REQUEST = "ADD_APPROVER_REQUEST";
export const ADD_APPROVER_SUCCESS = "ADD_APPROVER_SUCCESS";
export const ADD_APPROVER_ERROR = "ADD_APPROVER_ERROR";

export const EDIT_APPROVER_REQUEST = "EDIT_APPROVER_REQUEST";
export const EDIT_APPROVER_SUCCESS = "EDIT_APPROVER_SUCCESS";
export const EDIT_APPROVER_ERROR = "EDIT_APPROVER_ERROR";

export const DELETE_APPROVER_REQUEST = "DELETE_APPROVER_REQUEST";
export const DELETE_APPROVER_SUCCESS = "DELETE_APPROVER_SUCCESS";
export const DELETE_APPROVER_ERROR = "DELETE_APPROVER_ERROR";

export const CLEAR_USERS_ACTION_STATUS = "CLEAR_USERS_ACTION_STATUS";
export const CLEAR_USERS_LIST = "CLEAR_USERS_LIST";

export const GET_ADMIN_REQUEST = "GET_ADMIN_REQUEST";
export const GET_ADMIN_SUCCESS = "GET_ADMIN_SUCCESS";
export const GET_ADMIN_ERROR = "GET_ADMIN_ERROR";

export const GET_ADMIN_USER_REQUEST = "GET_ADMIN_USER_REQUEST";
export const GET_ADMIN_USER_SUCCESS = "GET_ADMIN_USER_SUCCESS";
export const GET_ADMIN_USER_ERROR = "GET_ADMIN_USER_ERROR";

export const GET_SINGLE_ADMIN_USER_REQUEST = "GET_SINGLE_ADMIN_USER_REQUEST";
export const GET_SINGLE_ADMIN_USER_SUCCESS = "GET_SINGLE_ADMIN_USER_SUCCESS";
export const GET_SINGLE_ADMIN_USER_ERROR = "GET_SINGLE_ADMIN_USER_ERROR";

export const ADD_ADMIN_USER_REQUEST = "ADD_ADMIN_USER_REQUEST";
export const ADD_ADMIN_USER_SUCCESS = "ADD_ADMIN_USER_SUCCESS";
export const ADD_ADMIN_USER_ERROR = "ADD_ADMIN_USER_ERROR";

export const EDIT_ADMIN_USER_REQUEST = "EDIT_ADMIN_USER_REQUEST";
export const EDIT_ADMIN_USER_SUCCESS = "EDIT_ADMIN_USER_SUCCESS";
export const EDIT_ADMIN_USER_ERROR = "EDIT_ADMIN_USER_ERROR";

export const DELETE_ADMIN_USER_REQUEST = "DELETE_ADMIN_USER_REQUEST";
export const DELETE_ADMIN_USER_SUCCESS = "DELETE_ADMIN_USER_SUCCESS";
export const DELETE_ADMIN_USER_ERROR = "DELETE_ADMIN_USER_ERROR";

export const CLEAR_LINKED_USER = "CLEAR_LINKED_USER";

export const DELETE_PASSENGER_RELATED_ENTITY_REQUEST =
  "DELETE_PASSENGER_RELATED_ENTITY_REQUEST";
export const DELETE_PASSENGER_RELATED_ENTITY_SUCCESS =
  "DELETE_PASSENGER_RELATED_ENTITY_SUCCESS";
export const DELETE_PASSENGER_RELATED_ENTITY_ERROR =
  "DELETE_PASSENGER_RELATED_ENTITY_ERROR";
