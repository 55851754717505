import React, { useState } from "react";
import { AppProps } from "next/app";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-day-picker/lib/style.css";
import "../src/styles/customGlobal.css";
import { useStore } from "../src/states/store";
import { globalTheme } from "../src/styles/default";
import { IELayout } from "../src/modules/core/layout/ieLayout";
import NextNprogress from "nextjs-progressbar";
// import Head from "next/Head";
import * as Sentry from "@sentry/node";
import { RewriteFrames } from "@sentry/integrations";
import getConfig from "next/config";
import { PageLoaderComp } from "../src/modules/core/components/fragments/PageLoader";

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  const config = getConfig();
  const distDir = `${config.serverRuntimeConfig.rootDir}/.next`;
  Sentry.init({
    enabled: process.env.NODE_ENV === "production",
    integrations: [
      new RewriteFrames({
        iteratee: (frame) => {
          frame.filename = frame.filename.replace(distDir, "app:///_next");
          return frame;
        },
      }),
    ],
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  });
}
// const Header = () => (
//   <Head>
//     <meta name="viewport" content="width=device-width, initial-scale=1" />
//     <meta name="msapplication-TileColor" content="#ffffff" />
//     <meta
//       name="msapplication-TileImage"
//       content="/assets/favicon/ms-icon-144x144.png"
//     />
//     <meta name="theme-color" content="#ffffff" />
//   </Head>
// );
const NextNprogressComp = () => (
  <NextNprogress
    color="#01B959"
    startPosition={0.3}
    stopDelayMs={0}
    height={4}
  />
);

const WrapperComp = ({ Component, pageProps }) => {
  let theme = globalTheme();
  return (
    <ThemeProvider theme={theme}>
      {/* <Header /> */}
      <CssBaseline />
      <Component {...pageProps} />
      <NextNprogressComp />
    </ThemeProvider>
  );
};
export default function App({ Component, pageProps }: AppProps) {
  const store = useStore(pageProps.initialReduxState);
  const persistor = persistStore(store);
  const [isIe, setIsIe] = useState(false);
  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
    setIsIe(false || !!document["documentMode"]);
  }, []);

  if (!isIe)
    return typeof window !== "undefined" ? (
      <Provider store={store}>
        <PersistGate persistor={persistor} loading={<PageLoaderComp />}>
          <WrapperComp Component={Component} pageProps={pageProps} />
        </PersistGate>
      </Provider>
    ) : (
      <Provider store={store}>
        <WrapperComp Component={Component} pageProps={pageProps} />
      </Provider>
    );
  else {
    return <IELayout />;
  }
}
