// Capitalize each word in a sentence
export function capitalize(text) {
  text = String(text)
    .toLowerCase()
    .split(" ")
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");

  return text;
}

// Capitalize first letter
export function capitalizeWord(string) {
  if (!string) return;
  return string.charAt(0).toUpperCase() + string.slice(1);
}
// lowercase first letter
export function lowerCaseWord(string) {
  if (!string) return;
  return string.charAt(0).toLowerCase() + string.slice(1);
}

export function hasNumber(myString) {
  return /\d/.test(myString);
}

export function snakeToCamel(string) {
  return string.replace(/(_\w)/g, function(m) {
    return m[1].toUpperCase();
  });
}

export function camelToSnake(string) {
  return string
    .replace(/[\w]([A-Z])/g, function(m) {
      return m[0] + "_" + m[1];
    })
    .toLowerCase();
}
