import { createTheme } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

const styles = {
  direction: "ltr",
  palette: {
    type: "light", // light or dark
    common: {
      black: "#3D4548",
      white: "#fff",
    },
    background: {
      paper: "#fff",
      default: "#F9F9F9",
      alternative: "#D9D9D9",
    },
    primary: {
      light: "#01B959",
      main: "#01A650",
      dark: "#008942",
      contrastText: "#fff",
    },
    secondary: {
      light: "#486684",
      main: "#34495E",
      dark: "#233342",
      contrastText: "#fff",
    },
    warning: {
      light: "#f9ba59",
      main: "#f8b144",
      dark: "#F8A930",
      contrastText: "#fff",
    },
    error: {
      light: "#E9775B",
      main: "#E45532",
      dark: "#9F3B23",
      contrastText: "#fff",
    },
    text: {
      primary: "#3D4548",
      secondary: "rgba(0, 0, 0, 0.54)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)",
    },
    action: {
      active: "rgba(0, 0, 0, 0.54)",
      hover: "rgba(0, 0, 0, 0.08)",
      hoverOpacity: 0.08,
      selected: "rgba(0, 0, 0, 0.14)",
      disabled: "rgba(0, 0, 0, 0.26)",
      disabledBackground: "rgba(0, 0, 0, 0.12)",
    },
  },
  shape: {
    borderRadius: 0,
  },
  typography: {
    fontFamily: [
      // "Tajawal",
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    button: {
      textTransform: "capitalize",
    },
    // useNextVariants: true,
  },
  overrides: {
    MuiDialog: {
      root: {
        zIndex: "1500 !important",
      },
    },
    MuiPopover: {
      root: {
        zIndex: "1600 !important",
      },
    },
    MuiPaper: {
      root: {
        zIndex: "1500 !important",
      },
      elevation1: {
        border: "1px solid #DADADA",
        boxShadow: "none",
      },
      elevation2: {
        border: "1px solid #DADADA",
        boxShadow: "none",
      },
    },
    MuiDrawer: {
      root: {
        color: "#fff",
      },
      paperAnchorDockedLeft: {
        borderRight: 0,
      },
      paperAnchorDockedRight: {
        borderLeft: 0,
      },
      paper: {
        overflowY: "scroll",
      },
    },
    MuiAppBar: {
      colorDefault: {
        backgroundColor: "#fafbfc",
        color: "#7d7d7d",
      },
    },
    MuiInputBase: {
      root: {
        "&.Mui-disabled": {
          backgroundColor: "hsl(0,0%,95%)",
        },
      },
    },
    MuiButton: {
      root: {
        color: "inherit",
        padding: 20,
        // textTransform: "capitalize",
      },
      contained: {
        boxShadow: "none",
      },
      outlined: {
        borderWidth: 2,
      },
      startIcon: {
        marginLeft: -12,
      },
      endIcon: {
        marginRight: -12,
      },
    },
    MuiButtonGroup: {
      root: {
        color: "white",
      },
      contained: {
        boxShadow: "none",
      },
    },
    MuiTabs: {
      root: {
        backgroundColor: "#F9F9F9",
        // borderBottom: "3px solid #e8e8e8",
        overflow: "visible",
      },
      indicator: {
        backgroundColor: "#01A650",
        height: 4,
        bottom: 4,
      },
      scrollButtons: {
        margin: "0 -19px",
        zIndex: 1,
        background: "#F9F9F9",
      },
    },
    MuiTab: {
      root: {
        textTransform: "initial",
        minWidth: "72 !important",
        // height: 55,
        // fontWeight: 600,
        fontSize: 14,
        opacity: "1 !important",
        letterSpacing: 0.5,
        color: "#34495E !important",
        // fontWeight: theme.typography.fontWeightRegular,
        // marginRight: theme.spacing(4),
        // fontFamily: [
        //   '-apple-system',
        //   'BlinkMacSystemFont',
        //   '"Segoe UI"',
        //   'Roboto',
        //   '"Helvetica Neue"',
        //   'Arial',
        //   'sans-serif',
        //   '"Apple Color Emoji"',
        //   '"Segoe UI Emoji"',
        //   '"Segoe UI Symbol"',
        // ].join(','),
        "&$selected": {
          // color: "#3D4548",
          // opacity: "0.7 !important",
        },

        "&:hover": {
          color: "#01A650",
          // opacity: 1,
        },
        // '&$tabSelected': {
        // },
        "&:focus": {
          // color: "#01A650",
        },
      },
    },
    MuiMenuItem: {
      root: {
        "&$selected": {
          backgroundColor: "#233342",
          color: "white",
        },
        "&$selected div, &$selected span": {
          color: "#fff",
        },
        "&$selected:hover": {
          backgroundColor: "#486684",
        },
        "&$selected:focus": {
          backgroundColor: "#486684",
        },
        "&:hover": {
          color: "#fff",
          backgroundColor: "#486684",
          opacity: 1,
        },
      },
    },

    MUIDataTable: {
      root: {
        boxShadow: "none",
      },
      paper: {
        border: "1px solid #e0e0e0",
      },
    },

    MUIDataTableBody: {
      root: {
        overflowX: "auto",
      },
    },
    MUIDataTableBodyRow: {
      root: {
        minHeight: 60,
      },
    },
    MUIDataTableFilter: {
      title: {
        color: "#424242",
        fontSize: "14px",
        fontWeight: 500,
      },
      resetLink: {
        color: "#01A650",
        backgroundColor: "#FFF",
        "&:hover": {
          color: "#E45532",
        },
      },
      checkboxListTitle: {
        color: "#424242",
        textAlign: "left",
        fontWeight: 500,
      },
      checkboxFormControlLabel: {
        color: "#4a4a4a",
      },
      checkbox: {
        "&$checked": {
          color: "#01A650",
        },
      },
      checked: {},
    },
    MUIDataTableHeadRow: {
      root: {
        height: 60,
        textTransform: "uppercase",
      },
      MUIDataTableHeadCell: {
        root: {
          fontWeight: "bold",
          fontSize: 12,
        },
        fixedHeader: {
          backgroundColor: "#FFF",
        },
        fixedHeaderCommon: {
          zIndex: 99,
        },
        sortActive: {
          color: "rgba(0, 0, 0, 0.87)",
        },
      },
      fixedHeader: {
        backgroundColor: "#FFF",
      },
      sortActive: {
        color: "rgba(0, 0, 0, 0.87)",
      },
    },
    MUIDataTablePagination: {
      root: {
        height: 80,
        border: 0,
      },
    },
    MUIDataTableSearch: {
      clearIcon: {
        "&:hover": {
          color: "#E45532",
        },
      },
      searchIcon: {
        color: "#586367",
      },
    },
    MUIDataTableSelectCell: {
      checkboxRoot: {
        "&$checked": {
          color: "#01A650",
        },
      },
      fixedHeaderCommon: {
        "& div button": {
          padding: "0",
        },
      },

      checked: {},
      expandDisabled: {
        // Soft hide the button.
        visibility: "hidden",
      },
    },
    MUIDataTableToolbar: {
      icon: {
        "&:hover": {
          color: "#01A650",
        },
      },
      iconActive: {
        color: "#008942",
      },
    },
    MUIDataTableToolbarSelect: {
      root: {
        backgroundColor: "#f7f7f7",
      },
      deleteIcon: {
        color: "#E45532",
      },
    },
    MUIDataTableViewCol: {
      title: {
        color: "#424242",
        textAlign: "left",
        fontWeight: 500,
      },
      checkboxRoot: {
        "&$checked": {
          color: "#008942",
        },
      },
      checked: {},
      label: {
        color: "#4a4a4a",
      },
    },
    MuiFormHelperText: {
      root: {
        whiteSpace: "normal",
      },
    },
    MuiExpansionPanel: {
      root: {
        border: "1px solid rgba(0, 0, 0, .125)",
        boxShadow: "none",
        "&:not(:last-child)": {
          borderBottom: 0,
        },
        "&:before": {
          display: "none",
        },
        "&$expanded": {
          margin: "auto",
        },
      },
      expanded: {},
    },
    MuiExpansionPanelSummary: {
      root: {
        // backgroundColor: "rgba(0, 0, 0, .03)",
        // borderBottom: "1px solid rgba(0, 0, 0, .125)",
        marginBottom: -1,
        minHeight: 56,
        "&$expanded": {
          minHeight: 56,
        },
      },
      content: {
        "&$expanded": {
          margin: "12px 0",
        },
      },
      expanded: {},
    },
    MuiExpansionPanelDetails: {
      root: {
        // padding: theme.spacing(2),
      },
    },
    MuiStepLabel: {
      label: {
        fontSize: 17,
        fontWeight: 500,

        "&$active": {
          fontSize: 17,
          fontWeight: 500,
        },
      },
      active: {},
    },
    MuiStepIcon: {
      root: {
        width: "1.7em",
        height: "1.7em",
        color: "#fff",
        border: `2px solid #01A650`,
        borderRadius: 200,
        // stroke: `#01A650`,
        // strokeWidth: 2,
        "& active": {
          border: 0,
        },
      },
      active: {
        border: `2px solid transparent`,
        "& $text": {
          fill: "#fff",
        },
      },
      completed: {
        border: `2px solid transparent`,
        "& $text": {
          fill: "#fff",
        },
      },
      text: {
        fill: "#01A650",
        // fontSize: 20,
      },
    },
    MuiStepConnector: {
      active: {
        "& $line": {
          borderColor: "#01A650",
        },
      },

      completed: {
        "& $line": {
          borderColor: "#01A650",
        },
      },
    },
    MuiStepContent: {
      root: {
        marginLeft: 21,
        marginTop: 0,
        borderLeftWidth: 2,
      },
    },
    MuiStepConnector: {
      active: {
        "& $line": {
          borderColor: "#01A650",
        },
      },
      completed: {
        "& $line": {
          borderColor: "#01A650",
        },
      },
      line: {
        // borderColor: "#eaeaf0",
        // borderTopWidth: 3,
        // borderRadius: 1,
      },
      vertical: {
        marginLeft: 21,
        // marginTop: -8,
        paddingBottom: 0,
      },
      horizontal: {
        marginTop: 10,
        // marginTop: -8,
        // paddingBottom: 0,
      },
      lineVertical: {
        borderLeftWidth: 2,
      },
    },
  },
  props: {
    MUIDataTable: {
      elevation: 0,
    },
  },
};

export const globalTheme = () => {
  const changeView = useSelector(({ core }) => core.tableCompactView);
  if (changeView) {
    styles.overrides.MuiTableCell = {
      root: {
        padding: "5px 16px",
        fontSize: "0.8rem",
        // textAlign: "center",
      },
      head: {
        padding: "5px 16px",
        // textAlign: "center",
      },
      paddingCheckbox: {
        width: "38px",
        padding: 0,
      },
      // "& MuiIconButton": {
      //   root: {
      //     padding: "4px",
      //   },
      // },
      // styles.overrides.MUIDataTableSelectCell = {
    };
    styles.overrides.PrivateSwitchBase = {
      root: {
        padding: "0 16px",
      },
    };
  } else {
    styles.overrides.MuiTableCell = undefined;
    styles.overrides.PrivateSwitchBase = undefined;
  }

  return createTheme({ ...styles });
};

export const alertDialogTheme = () => {
  return createTheme({ ...styles });
};
