import React from "react";

export const IELayout = () => {
  return (
    <div id="IE-Browser">
      <div className="IE-top-container">
        <div className="IE-container">
          <img src="assets/svg/rehlati-logo.svg" alt="rehlati-logo" />
          <h1>Oops !!! Internet Explorer is not Supported.</h1>
          <div>
            Please use Google Chrome or Mozilla Firefox browser to access our
            services.
            <br />
            If you already have new browser installed, you can use it to visit
            this URL.
          </div>
          <div>
            <input
              id="IE-input"
              value={process.browser && window.location.href}
              readOnly
            />
          </div>
        </div>
      </div>
      <div className="IE-footer"></div>
      <style jsx>{`
        #IE-Browser {
          min-height: 100vh;
          position: relative;
          background: #f9f9f9;
        }
        .IE-top-container {
          background-image: url("/assets/svg/header.svg");
          background-repeat: no-repeat;
          background-position-x: right;
          height: 100%;
        }
        .IE-container {
          font-size: 1rem;
          font-family: Roboto, "Helvetica Neue", Arial, sans-serif,
            "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
          text-align: center;
          padding-top: 15%;
        }
        .IE-container img {
          height: 120px;
        }
        .IE-container h1 {
          margin-top: 40px;
          font-size: 2rem;
          color: #ffa600;
        }
        .IE-container div {
          font-size: 1.1rem;
          line-height: 1.43;
          color: #34495e;
        }
        .IE-container #IE-input {
          margin-top: 20px;
          padding: 7px;
          border: none;
          border-radius: 4px;
          min-width: 400px;
          text-align: center;
          cursor: text;
          background: #eee;
          color: #777;
          margin-bottom: 50px;
        }
        .IE-footer {
          width: 100%;
          bottom: 0;
          height: 20px;
          position: absolute;
          align-self: center;
          background: #01a650;
        }
      `}</style>
      <style global jsx>{`
        html {
          position: relative;
        }
        body {
          margin: 0;
        }
      `}</style>
    </div>
  );
};
