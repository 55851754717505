export const app = {
  appName: "Rehlati",
  userPath: "",
  // appHostname: process.env.NEXT_PUBLIC_AppHostname,
  // appSSL: (process.env.NEXT_PUBLIC_AppSSL),
  // appPort: process.env.NEXT_PUBLIC_AppPort,
  // appPortDev: 5443,
  // sentryDSN: "https://8f44dfcbe7664993874c3a2ef7ab46af@sentry.emushrif.om/4",
  sentryDSN:
    // "http://f090b3749a9042c1b22ec1e0f4b0bb49@sentry.emushrif.om:9000/3",//test-2
    "https://1c523c200bd9463ead3963f40e2ae6ac@sentry.emushrif.om/2", //prodUrl
  // "http://f090b3749a9042c1b22ec1e0f4b0bb49@med-dev.emushrif.om:9000/3", //test-1
  googleAPI: process.env.NEXT_PUBLIC_GoogleAPI,
};

export const appConfig = {
  appId: "7d11095f-01af-49ea-87ff-fda9cc38f056",
  redirectUri: "http://localhost:5001/login",
  scopes: ["user.read"],
  authority:
    "https://login.microsoftonline.com/7d11095f-01af-49ea-87ff-fda9cc38f056",
};

export const coreService = {
  hostname: process.env.NEXT_PUBLIC_TransportHostname,
  ssl: process.env.NEXT_PUBLIC_TransportSSL,
  port: process.env.NEXT_PUBLIC_TransportPort,
  apiPrefix: "api",
  portDev: 5443,
};

export const realtime = {
  hostname: process.env.NEXT_PUBLIC_RealtimeHostname,
  ssl: process.env.NEXT_PUBLIC_RealtimeSSL,
  port: process.env.NEXT_PUBLIC_RealtimePort,
};

export const settings = {
  adminPath: "/admin",
  rowsPerPage: 50,
  rowsPerPageOptions: [10, 25, 50, 75, 100, 1000],
  itemsPerPage: 10,
  timeFormatParsing: ["YYYY-MM-DDTHH:mm:ss:SSZ", "YYYY-MM-DDTHH:mm:ss.sssZ"],
  ticketTimeFormat: "Do MMM, HH:mm",
  restTime: 5, // rest time between stops in minutes
};

export const routingService = {
  hostname: process.env.NEXT_PUBLIC_RoutingHostname,
  ssl: process.env.NEXT_PUBLIC_RoutingSSL,
  port: process.env.NEXT_PUBLIC_RoutingPort,
};

export const staticService = {
  hostname: process.env.NEXT_PUBLIC_TransportHostname,
  ssl: process.env.NEXT_PUBLIC_TransportSSL,
  port: process.env.NEXT_PUBLIC_TransportPort,
  portDev: 5443,
  rootPath: "static",
  templates: "templates",
};

export const userTypes = {
  admin: 0,
  focalPoint: 1,
  contractFocalPoint: 2,
  contractor: 3,
  driver: 4,
};

export const timeConstants = {
  browserCachedData: 5,
};
const config = {
  app,
  appConfig,
  coreService,
  realtime,
  settings,
  staticService,
  routingService,
  userTypes,
  timeConstants,
};

export default config;
