export const GET_BOOKING_REQUEST = "GET_BOOKING_REQUEST";
export const GET_BOOKING_SUCCESS = "GET_BOOKING_SUCCESS";
export const GET_BOOKING_ERROR = "GET_BOOKING_ERROR";

export const GET_PARK_RIDE_BOOKING_REQUEST = "GET_PARK_RIDE_BOOKING_REQUEST";
export const GET_PARK_RIDE_BOOKING_SUCCESS = "GET_PARK_RIDE_BOOKING_SUCCESS";
export const GET_PARK_RIDE_BOOKING_ERROR = "GET_PARK_RIDE_BOOKING_ERROR";

export const GET_PARK_RIDE_BOOKING_HISTORY_REQUEST =
  "GET_PARK_RIDE_BOOKING_HISTORY_REQUEST";
export const GET_PARK_RIDE_BOOKING_HISTORY_SUCCESS =
  "GET_PARK_RIDE_BOOKING_HISTORY_SUCCESS";
export const GET_PARK_RIDE_BOOKING_HISTORY_ERROR =
  "GET_PARK_RIDE_BOOKING_HISTORY_ERROR";

export const GET_AVAILABLE_TRIPS_REQUEST = "GET_AVAILABLE_TRIPS_REQUEST";
export const GET_AVAILABLE_TRIPS_SUCCESS = "GET_AVAILABLE_TRIPS_SUCCESS";
export const GET_AVAILABLE_TRIPS_ERROR = "GET_AVAILABLE_TRIPS_ERROR";

export const ADD_BOOKING_REQUEST = "ADD_BOOKING_REQUEST";
export const ADD_BOOKING_SUCCESS = "ADD_BOOKING_SUCCESS";
export const ADD_BOOKING_ERROR = "ADD_BOOKING_ERROR";

export const ADD_GROUP_BOOKING_REQUEST = "ADD_GROUP_BOOKING_REQUEST";
export const ADD_GROUP_BOOKING_SUCCESS = "ADD_GROUP_BOOKING_SUCCESS";
export const ADD_GROUP_BOOKING_ERROR = "ADD_GROUP_BOOKING_ERROR";

export const EDIT_BOOKING_REQUEST = "EDIT_BOOKING_REQUEST";
export const EDIT_BOOKING_SUCCESS = "EDIT_BOOKING_SUCCESS";
export const EDIT_BOOKING_ERROR = "EDIT_BOOKING_ERROR";

export const CANCEL_BOOKING_REQUEST = "CANCEL_BOOKING_REQUEST";
export const CANCEL_BOOKING_SUCCESS = "CANCEL_BOOKING_SUCCESS";
export const CANCEL_BOOKING_ERROR = "CANCEL_BOOKING_ERROR";

export const CANCEL_BULK_BOOKING_REQUEST = "CANCEL_BULK_BOOKING_REQUEST";
export const CANCEL_BULK_BOOKING_SUCCESS = "CANCEL_BULK_BOOKING_SUCCESS";
export const CANCEL_BULK_BOOKING_ERROR = "CANCEL_BULK_BOOKING_ERROR";

export const DELETE_BOOKING_REQUEST = "DELETE_BOOKING_REQUEST";
export const DELETE_BOOKING_SUCCESS = "DELETE_BOOKING_SUCCESS";
export const DELETE_BOOKING_ERROR = "DELETE_BOOKING_ERROR";

export const DELETE_BULK_BOOKING_REQUEST = "DELETE_BULK_BOOKING_REQUEST";
export const DELETE_BULK_BOOKING_SUCCESS = "DELETE_BULK_BOOKING_SUCCESS";
export const DELETE_BULK_BOOKING_ERROR = "DELETE_BULK_BOOKING_ERROR";

export const GET_POSSIBLE_ROUTES_REQUEST = "GET_POSSIBLE_ROUTES_REQUEST";
export const GET_POSSIBLE_ROUTES_SUCCESS = "GET_POSSIBLE_ROUTES_SUCCESS";
export const GET_POSSIBLE_ROUTES_ERROR = "GET_POSSIBLE_ROUTES_ERROR";

export const GET_FUTURE_TRIP_DATE_REQUEST = "GET_FUTURE_TRIP_DATE_REQUEST";
export const GET_FUTURE_TRIP_DATE_SUCCESS = "GET_FUTURE_TRIP_DATE_SUCCESS";
export const GET_FUTURE_TRIP_DATE_ERROR = "GET_FUTURE_TRIP_DATE_ERROR";

export const CLEAR_BOOKING_ACTION_STATUS = "CLEAR_BOOKING_ACTION_STATUS";

export const VERIFY_STOPS_REQUEST = "VERIFY_STOPS_REQUEST";
export const VERIFY_STOPS_SUCCESS = "VERIFY_STOPS_SUCCESS";
export const VERIFY_STOPS_ERROR = "VERIFY_STOPS_ERROR";

export const GET_LINKED_TRIP_REQUEST = "GET_LINKED_TRIP_REQUEST";
export const GET_LINKED_TRIP_SUCCESS = "GET_LINKED_TRIP_SUCCESS";
export const GET_LINKED_TRIP_ERROR = "GET_LINKED_TRIP_ERROR";

export const GET_LINKED_VEHICLE_REQUEST = "GET_LINKED_VEHICLE_REQUEST";
export const GET_LINKED_VEHICLE_SUCCESS = "GET_LINKED_VEHICLE_SUCCESS";
export const GET_LINKED_VEHICLE_ERROR = "GET_LINKED_VEHICLE_ERROR";

export const GET_LINKED_ROUTE_REQUEST = "GET_LINKED_ROUTE_REQUEST";
export const GET_LINKED_ROUTE_SUCCESS = "GET_LINKED_ROUTE_SUCCESS";
export const GET_LINKED_ROUTE_ERROR = "GET_LINKED_ROUTE_ERROR";

export const MOVE_PASSENGERS_REQUEST = "MOVE_PASSENGERS_REQUEST";
export const MOVE_PASSENGERS_SUCCESS = "MOVE_PASSENGERS_SUCCESS";
export const MOVE_PASSENGERS_ERROR = "MOVE_PASSENGERS_ERROR";

export const MOVE_BOOKING_LIST = "MOVE_BOOKING_LIST";

export const CHECK_MOVE_BOOKING_REQUEST = "CHECK_MOVE_BOOKING_REQUEST";
export const CHECK_MOVE_BOOKING_SUCCESS = "CHECK_MOVE_BOOKING_SUCCESS";
export const CHECK_MOVE_BOOKING_ERROR = "CHECK_MOVE_BOOKING_ERROR";

export const GET_BOOKING_TEMPLATE_REQUEST = "GET_BOOKING_TEMPLATE_REQUEST";
export const GET_BOOKING_TEMPLATE_SUCCESS = "GET_BOOKING_TEMPLATE_SUCCESS";
export const GET_BOOKING_TEMPLATE_ERROR = "GET_BOOKING_TEMPLATE_ERROR";

export const ADD_BOOKING_TEMPLATE_REQUEST = "ADD_BOOKING_TEMPLATE_REQUEST";
export const ADD_BOOKING_TEMPLATE_SUCCESS = "ADD_BOOKING_TEMPLATE_SUCCESS";
export const ADD_BOOKING_TEMPLATE_ERROR = "ADD_BOOKING_TEMPLATE_ERROR";

export const EDIT_BOOKING_TEMPLATE_REQUEST = "EDIT_BOOKING_TEMPLATE_REQUEST";
export const EDIT_BOOKING_TEMPLATE_SUCCESS = "EDIT_BOOKING_TEMPLATE_SUCCESS";
export const EDIT_BOOKING_TEMPLATE_ERROR = "EDIT_BOOKING_TEMPLATE_ERROR";

export const CHECK_EDIT_BOOKING_REQUEST = "CHECK_EDIT_BOOKING_REQUEST";
export const CHECK_EDIT_BOOKING_SUCCESS = "CHECK_EDIT_BOOKING_SUCCESS";
export const CHECK_EDIT_BOOKING_ERROR = "CHECK_EDIT_BOOKING_ERROR";

export const DELETE_BOOKING_TEMPLATE_REQUEST =
  "DELETE_BOOKING_TEMPLATE_REQUEST";
export const DELETE_BOOKING_TEMPLATE_SUCCESS =
  "DELETE_BOOKING_TEMPLATE_SUCCESS";
export const DELETE_BOOKING_TEMPLATE_ERROR = "DELETE_BOOKING_TEMPLATE_ERROR";

export const GET_PASSENGER_ON_TEMPLATE_REQUEST =
  "GET_PASSENGER_ON_TEMPLATE_REQUEST";
export const GET_PASSENGER_ON_TEMPLATE_SUCCESS =
  "GET_PASSENGER_ON_TEMPLATE_SUCCESS";
export const GET_PASSENGER_ON_TEMPLATE_ERROR =
  "GET_PASSENGER_ON_TEMPLATE_ERROR";

export const GET_STOP_RELATED_TO_ROUTE_REQUEST =
  "GET_STOP_RELATED_TO_ROUTE_REQUEST";
export const GET_STOP_RELATED_TO_ROUTE_SUCCESS =
  "GET_STOP_RELATED_TO_ROUTE_SUCCESS";
export const GET_STOP_RELATED_TO_ROUTE_ERROR =
  "GET_STOP_RELATED_TO_ROUTE_ERROR";

export const ADD_PASSENGER_ON_TEMPLATE_REQUEST =
  "ADD_PASSENGER_ON_TEMPLATE_REQUEST";
export const ADD_PASSENGER_ON_TEMPLATE_SUCCESS =
  "ADD_PASSENGER_ON_TEMPLATE_SUCCESS";
export const ADD_PASSENGER_ON_TEMPLATE_ERROR =
  "ADD_PASSENGER_ON_TEMPLATE_ERROR";

export const DELETE_PASSENGER_ON_TEMPLATE_REQUEST =
  "DELETE_PASSENGER_ON_TEMPLATE_REQUEST";
export const DELETE_PASSENGER_ON_TEMPLATE_SUCCESS =
  "DELETE_PASSENGER_ON_TEMPLATE_SUCCESS";
export const DELETE_PASSENGER_ON_TEMPLATE_ERROR =
  "DELETE_PASSENGER_ON_TEMPLATE_ERROR";

export const REVIEW_SHIFT_PASSENGER_REQUEST = "REVIEW_SHIFT_PASSENGER_REQUEST";
export const REVIEW_SHIFT_PASSENGER_SUCCESS = "REVIEW_SHIFT_PASSENGER_SUCCESS";
export const REVIEW_SHIFT_PASSENGER_ERROR = "REVIEW_SHIFT_PASSENGER_ERROR";

export const ADD_SHIFT_BOOKING_REQUEST = "ADD_SHIFT_BOOKING_REQUEST";
export const ADD_SHIFT_BOOKING_SUCCESS = "ADD_SHIFT_BOOKING_SUCCESS";
export const ADD_SHIFT_BOOKING_ERROR = "ADD_SHIFT_BOOKING_ERROR";

export const GET_FEEDBACK_REQUEST = "GET_FEEDBACK_REQUEST";
export const GET_FEEDBACK_SUCCESS = "GET_FEEDBACK_SUCCESS";
export const GET_FEEDBACK_ERROR = "GET_FEEDBACK_ERROR";

export const EDIT_PARK_RIDE_BOOKING_REQUEST = "EDIT_PARK_RIDE_BOOKING_REQUEST";
export const EDIT_PARK_RIDE_BOOKING_ERROR = "EDIT_PARK_RIDE_BOOKING_ERROR";
export const EDIT_PARK_RIDE_BOOKING_SUCCESS = "EDIT_PARK_RIDE_BOOKING_SUCCESS";
